export default class Website {
  constructor() {
    this.init()
  }

  init() {
    const root = document.documentElement;
    const switchColor = document.querySelectorAll(".switch_color");
    const firstLogo = document.querySelectorAll(".logo--first");
    var switchState = "on";

    if(document.cookie.includes("switchColor=off")){
      switchState = "on";
      switchMode();
    }
  
    function switchMode() {
      switch (switchState) {
        case 'on':
          document.cookie = "switchColor=off";
          firstLogo.forEach(logo => logo.setAttribute("style", "filter: invert();"));
          root.setAttribute(
            "style", "--color-bg: black; --color-text: white; --color-link: white;");
          switchState = "off";
          break;
        case 'off':
          document.cookie = "switchColor=on";
          firstLogo.forEach(logo => logo.setAttribute("style", ""));
          root.setAttribute(
            "style", "--color-bg: white; --color-text: black; --color-link: black;");
          switchState = "on";
          break;
      }
    }

    switchColor.forEach(element =>
      element.onclick = function () {
        switchMode()
      }
    );

    document.addEventListener('keydown', (e) => {
      if(e.key == "n"){
        switchMode()
      }
    });
  }

}
