import "./_lib/modernizr";
import "../scss/application.scss";

import Website from "./_modules/website";
import Home from "./_modules/home";
import Infos from "./_modules/infos";
import Default from "./_modules/default";

new Website();
new Home();
new Infos();
new Default();