import Swiper from "swiper/swiper-bundle.js";
import Loadeer from "loadeer";

import "swiper/swiper.scss";

export default class Default {
  constructor() {
    this.init();
  }

  init() {
    ///// Arrow cursor

    const cursor = document.querySelector(".cursor");
    var halfWindowW = window.innerWidth / 2;

    if (cursor) {

      document.onmousemove = (event) => {
        if (event.clientX < halfWindowW) {
          cursor.setAttribute(
            "style",
            "opacity:1; top:" +
              event.clientY +
              "px; left:" +
              event.clientX +
              "px; transform: rotate(180deg);"
          );
        } else {
          cursor.setAttribute(
            "style",
            "opacity:1; top:" +
              event.clientY +
              "px; left:" +
              event.clientX +
              "px;"
          );
        }
      };

      document.onclick = (event) => {
        if (event.clientX < halfWindowW) {
          swiper.slidePrev(0);
        } else {
          swiper.slideNext(0);
        }
      };

      window.addEventListener("resize", function (event) {
        windowW = window.innerWidth;
      });
    }

    //  Mute video

    const muteBtn = document.querySelector(".project__muteBtn");
    const swiperVideo = document.querySelector(".swiper-video");
    const swiperSlide = document.querySelector(".swiper-slide");

    if (muteBtn) {

      swiperSlide.onmouseover = () => {
        muteBtn.style.display = "block";
      }
      swiperSlide.onmouseout  = () => {
        muteBtn.style.display = "none";
      }

      cursor.remove();

      document.querySelector(".project").classList.add("project-video");

      muteBtn.onclick = () => {
          swiperVideo.muted = !swiperVideo.muted;
          if (muteBtn.innerHTML === "Unmute") {
            muteBtn.innerHTML = "Mute";
          } else {
            muteBtn.innerHTML = "Unmute";
          }
      };
    }

    //  Cancel cursor

    const footer = document.querySelector(".footer");
    const footerLink = document.querySelector(".footer a");

    if (cursor) {
      footer.onmouseover = (event) => {
        cursor.classList.add("hide");
      };
      footerLink.onmouseenter = (event) => {
        cursor.classList.add("hide");
      };

      footer.onmouseout = (event) => {
        cursor.classList.remove("hide");
      };
    }

    //// Swiper Portfolio

    const swiperContainer = document.querySelector(".swiper-wrapper");

    if (swiperContainer) {

        var swiper = new Swiper(".swiper", {
          slidesPerView: 1,
          speed: 0,
          loop: true,
          allowTouchMove: false,
          keyboard: {
            enabled: true,
          },
        });

        const pageIndex = document.querySelector(".folio__current");

        swiper.on("slideChange", function () {
          const index = swiper.realIndex + 1;
          pageIndex.innerText = "" + index + "";
        });

    }

    //// Lazy loading

    const instance = new Loadeer();
    instance.observe();

    const imageBlocs = document.querySelectorAll(".swiper-img");

    // Trigger the load before the image appears in the viewport

    if (cursor) {
      imageBlocs.forEach((element) => {
        instance.triggerLoad(element);
      });
    }
  }
}
