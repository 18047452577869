import Swiper from "swiper/swiper-bundle.js";
import Loadeer from "loadeer";

import "swiper/swiper.scss";

export default class Home {
  constructor() {
    this.init();
  }

  init() {
    //// Toggle thumbnails

    const gridBlocs = document.querySelectorAll(".grid__bloc");
    const grid = document.querySelector(".grid");

    if (window.innerWidth > 900) {
      gridBlocs.forEach((element) => {
        element.addEventListener("mouseenter", function (event) {
          this.querySelector(".grid__bloc__image").classList.toggle(
            "grid__bloc__image--show"
          );
        });
      });
    }

    //// Toggle images

    const imageBtn = document.querySelector(".switch");

    if (imageBtn) {
      imageBtn.addEventListener("click", function (event) {
        imageBtn.classList.toggle("switch--minus");

        if (flippingStatus === true) {
          flippingStatus = false;
          gridBlocs.forEach((element) => {
            element
              .querySelector(".grid__bloc__image")
              .classList.remove("grid__bloc__image--show");
          });
        } else {
          flippingStatus = true;
          gridBlocs.forEach((element) => {
            element
              .querySelector(".grid__bloc__image")
              .classList.add("grid__bloc__image--show");
          });
        }
      });
    }

    //// Display random blocs on mobile

    var flippingStatus = true;

    const gridBlocsImage = document.querySelectorAll(".grid__bloc__image");

    function flippingBlocs(rand) {
      setTimeout(() => {
        if (flippingStatus === true) {
          gridBlocsImage.forEach((element) => {
            element.classList.remove("grid__bloc__image--show");
          });

          const uniqueRandom = (...compareNumbers) => {
            let uniqueNumber;
            do {
              uniqueNumber = Math.floor(Math.random() * gridBlocsImage.length);
            } while (compareNumbers.includes(uniqueNumber));
            return uniqueNumber;
          };

          const randomBloc = uniqueRandom();
          const randomBlocTwo = uniqueRandom(randomBloc);
          const randomBlocThree = uniqueRandom(randomBloc, randomBlocTwo);
          const randomBlocFour = uniqueRandom(
            randomBloc,
            randomBlocTwo,
            randomBlocThree
          );
          const randomBlocFive = uniqueRandom(
            randomBloc,
            randomBlocTwo,
            randomBlocThree,
            randomBlocFour
          );
          const randomBlocSix = uniqueRandom(
            randomBloc,
            randomBlocTwo,
            randomBlocThree,
            randomBlocFour,
            randomBlocFive
          );

          const randomBlocs = [
            randomBloc,
            randomBlocTwo,
            randomBlocThree,
            randomBlocFour,
            randomBlocFive,
            randomBlocSix,
          ];

          for (let i = 0; i < 5; i++) {
            gridBlocsImage[Math.round(randomBlocs[i])].classList.add(
              "grid__bloc__image--show"
            );
          }

          const insideRandomTimeout = Math.random() * (3000 - 300) + 300;

          flippingBlocs(200 + insideRandomTimeout);
        }
      }, rand);
    }

    if (/Mobi|Android/i.test(navigator.userAgent)) {
      if (grid) {
        console.log('????');
        const randomTimeout = 200 + (Math.random() * (3000 - 300) + 300);

        flippingBlocs(randomTimeout);
      }
    }

    //// Toggle categories

    const categories = document.querySelector(".categories");
    const categoryBtnFirst = document.querySelector(
      ".categories__button--first"
    );
    const categoryBtn = document.querySelectorAll(".categories__button");
    const categoryBtnAll = document.querySelector(".categories__button--all");
    const footer = document.querySelector(".footer");

    if (categories) {
      categoryBtnFirst.addEventListener("click", function (event) {
        flippingStatus = false;
        
        categories.classList.toggle("categories--open");
        if (window.innerWidth < 900) {
          footer.classList.toggle("footer--categories");
        }
      });

      categoryBtnAll.addEventListener("click", function (event) {
        categories.classList.toggle("categories--open");
        gridBlocs.forEach((element) => {
          element.classList.remove("grid__bloc--hide");
        });
        if (window.innerWidth < 900) {
          footer.classList.toggle("footer--categories");
          flippingStatus = true;
          flippingBlocs(500);
        }
     
        gridBlocsImage.forEach((element) => {
          element.classList.remove("grid__bloc__image--hide");
        });
      });

      categoryBtn.forEach((element) => {
        const btnData = element.dataset.category;
        element.addEventListener("click", function (event) {
          if (element.classList.contains("categories__button--all")) {
          } else {
            gridBlocsImage.forEach((element) => {
              element.classList.remove("grid__bloc__image--show");
              element.classList.add("grid__bloc__image--hide");
            });
          }

          gridBlocs.forEach((element) => {
            const blocData = element.dataset.category;
            if (blocData.includes(btnData) == false) {
              element.classList.add("grid__bloc--hide");
            } else {
              element.classList.remove("grid__bloc--hide");
              flippingStatus = true;
            }
          });
        });
      });
    }
  }
}
